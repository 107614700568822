.main-container {
  margin-top: 105px;
  margin-bottom: 60px;
}

.circle {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #eaebf1;
  border: 1px solid #dbdce4 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1b3963;
  font-weight: bold;
}
.color {
  background-color: #ffffcc;
}
.listTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bg-color {
  background-color: #f74e7c !important;
}
.position {
  position: fixed !important;
  top: 57px !important;
}
.attachment {
  border: 1px solid rgb(192, 186, 186);
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 10px;
  margin-right: 8px;
}
