.badge-footer-menu {
  min-width: 25px;
  height: 25px;
  line-height: 2.2em !important;
  font-size: 12px;
  color: #fff;
  padding: 0 6px !important;
  position: absolute;
  right: 50%;
  transform: translateX(130%);
  top: -10px;
  border-radius: 100px;
  background-color: red;
}
/*blink the unread messages badge*/
.blinker {
  animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}
/* big circle button*/
.btn-circle {
  display: block;
  height: 180px !important;
  width: 180px;
  border-radius: 50% !important;
  /*start button colors*/
  background-color: #013458 !important;
  border: 1px solid #013458;
  /* End button colors*/
  /*background-color: #FF396F !important;
  border: 1px solid #FF396F;*/
  font-size: 30px !important;
  color: #fff !important;
}
.btn {
  height: 3.3rem;
  padding: 3px 18px;
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 600;
  color: #fff;
  box-shadow: none !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
  text-decoration: none !important;
  border-radius: 4px;
  border-width: 2px;
}
.two-buttons {
  position: fixed;
  top: 70%;
  width: 100%;
  display: flex;
  justify-content: center;
  justify-content: space-around;
}
.btn-circle1 {
  display: block;
  height: 3.3rem !important;
  width: 5.5rem !important;
  border-radius: 0% !important;

  /*color when buttons are disabled*/
  /* background-color: #eaebf1 !important;
  border: 1px solid #dbdce4 !important;
  color: #b7b8c4 !important; */

  /*color when buttons are enabled*/
  /* background-color: #013458 !important;
  border: 1px solid #013458 !important;
  color: #e0e2eb !important; */

  font-size: 1rem !important;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled {
  background-color: #eaebf1 !important;
  border: 1px solid #dbdce4 !important;
  color: #b7b8c4 !important;
  border-radius: 4px !important;
  font-weight: 500;
}
.enabled {
  background-color: #013458 !important;
  border: 1px solid #013458 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-weight: 500;
}

.center {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  justify-content: center;
}
.center1 {
  position: fixed;
  width: 100%;
  top: 32%;

  /* justify-content: center; */
}
.center2 {
  width: 100%;
  position: fixed;
  top: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
}
.border {
  border-radius: 0% !important;
}
.red {
  background-color: #f91942 !important;

  border-color: #f91942 !important;
}
.spinner {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnwidth {
  margin-right: auto !important;
  margin-left: auto !important;
  background: #ffffff !important;
  color: black !important;
  border: 1px solid black !important;

}
.spinner1 {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.terms {
  color: #1266f1 !important;
  border-bottom: 1px solid #9a9fa5;
}

.modal-header {
  background: #dee2e6;
}
.modal-footer {
  background: #dee2e6;
}


